.dropdown-menu .fas {
  color: dimgray;
}

.table.user-groups tr:first-of-type td {
  border-top: none;
}

.table.user-groups tr.form-row td {
  border-top: none;
  margin-top: 20px;
}