:export {
  earthiVividGreen: #75b626;
  earthiGrassGreen: #2d9147;
  earthiSeaGreen: #426862;
  earthBlack: #000;
  earthiSpaceGrey: #565656;
  earthiMidGrey: #9c9c9c;
  earthiLightGrey: #c5c6c6;
  earthiWhite: #fff;
  earthiDeepSea: #133042;
}

body .sidebar {
  min-height: 100vh;
}

.navbar-brand {
  margin-top: 9px;
  margin-left: 8px;
}

#sidebarToggle {
  position: absolute;
  width: 50px;
  top: 21px;
  right: 0;
}

.navbar-nav .nav-item .nav-link .badge {
  margin-left: -0.4rem;
}

.sidebar {
  border-right: 3px double #eee;
}

.accountButton {
  position: absolute;
  bottom: 25px;
  width: 100%;
}
.accountButton > .btn-group > .btn {
  background-color: #ddd;
  border-radius: 20px;
  width: 80px;
  height: 40px;
}