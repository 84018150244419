.signup-modal {
  position: relative;
  height: 400px;
  overflow-y: scroll;
}

.signup-modal ol {
  margin-left: 10px;
  padding-left: 10px;
}

.signup-modal ol ol {
  margin: 5px 0 15px 10px;
  padding-left: 10px;
}

.signup-modal ol li {
  margin-bottom: 3px;
}